:root{
    --color-1: #BA80E6;
    --color-2: #D1ABEF;
    --color-3: #E9D5F7;
    --color-4: #391952;
    --color-5: #8d73b0;
    --background: #f4f4f4
}

.view-cards{
    height: 800vh;
    width: 100%;
}

.card-scroll-container{
    background: var(--background);
    width: 100%;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;
}

.card-scroll{
    content: '';
    height: 330px;
    width: 280px;
    border: none;
    position: fixed;
    border-radius: 20px;
}

.card-scroll__text{
    position: fixed;
    font-size: 100px;
    color: var(--color-4);
    top: inherit;
}

.card-scroll:nth-child(2){
    background: #fff;
    transform: rotate(-12deg);
}

.card-scroll:nth-child(3){
    background: var(--color-3);
    transform: rotate(-7deg);
}

.card-scroll:nth-child(4){
    background: var(--color-2);
    transform: rotate(-3deg);
}

.card-scroll:nth-child(5){
    background: var(--color-1);
    transform: rotate(2deg);
}

@media (max-width: 799px) {
    .card-scroll__text{
        font-size: 70px;
    }
}
