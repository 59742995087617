

.skill-container{
    display: flex;
    animation: carouselLoop 30s infinite linear;
}

.skills:hover .skill-container{
    animation-play-state: paused;
}

.skill{
    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    height: 75px;
    width: 185px;

    border-radius: 10px;
    margin: 0 5px;

    background: rgba(255, 255, 200, 0.1);
    border-radius: 16px;
    box-shadow: -1px 2px 6px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255,255,255,0.2);

    padding: 5px;
}

.skill span{
    color: var(--background);
    font-weight: bold;
}

.skill-logo{
    height: 80%;
}

@keyframes carouselLoop {
    0%{
        transform: translateX(0);
    }
    100%{
        transform: translateX(-100%);
    }
}

@keyframes carouselLoopRight {
    0%{
        transform: translateX(-100%);
    }
    100%{
        transform: translateX(0%);
    }
}