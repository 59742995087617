@import url("../../styles/variables.css");

nav {
  position: relative;
  width: 100%;
}

.navigation {
  display: grid;
  grid-template-columns: 60px 1fr;
  height: 60px;

  position: fixed;
  width: inherit;

  background: none;
  /* box-shadow: 0px -8px 22px rgba(0,0,0,0.5); */
  z-index: 5;
}

.logo {
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.logo img {
  width: 40px;
}

.navigation__menu {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  position: relative;
}

.navigation__menu button {
  margin: 0 10px;
}

.toggle {
  content: "";
  width: 12px;
  height: 12px;
  background: orange;
  border-radius: 3px;
  cursor: pointer;
  border: none;
  overflow: hidden;
  position: relative;

  left: 0;

  animation: buttonRight 0.5s forwards;
}

.toggle.active {
  width: 12px;
  height: 12px;
  border-radius: 3px;
  position: relative;
  left: -50px;
  animation: buttonLeft 0.5s forwards;
}

.menu {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 4;
  background: var(--color-black);
  top: -100%;

  display: grid;
  place-content: center;
  place-items: center;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));

  animation: menuUp 0.5s forwards;

  font-family: "Fjalla One", sans-serif;
}

.menu.active {
  top: 0;

  animation: menuDown 0.5s forwards;
}

.circle_menu {
  content: "";
  width: 60px;
  height: 60px;
  position: absolute;
  background: var(--background);
  border-radius: 100%;
  mix-blend-mode: difference;

  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  pointer-events: none;

  display: none;
}

.circle_menu.active {
  display: block;
}

.menu_links {
  width: 100%;
}

.menu_links ul,
.menu_links a {
  list-style: none;
  text-decoration: none;
}

.menu_links ul {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 15px;

  border-right: 1px solid var(--background);
}

.menu_links a {
  color: var(--background);
  font-size: 30px;
}

.menu_animations {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.menu_animations h1 {
  color: var(--background);
}

.menu_animations h1:nth-child(1) {
  color: var(--background);
  font-size: 50px;
}

.menu_animations h1:nth-child(2) {
  color: var(--background);
  font-size: 25px;
}

.menu_animations .button {
  border: 1px solid var(--background);
  background: none;
  color: var(--background);
  padding: 7px;
  border-radius: 4px;
  margin-top: 10px;
  text-decoration: none;
  cursor: pointer;
}

.cv-link{
  color: var(--background);
  font-size: 12px;
  margin-top: 10px;
  text-decoration: none;
}

@media (max-width: 799px) {
  .menu {
    gap: 20px;
  }

  .menu_links ul {
    border-right: none;
    border-bottom: 1px solid var(--background);
    width: 70%;
    margin: auto;
    padding-bottom: 20px;
  }
}

@keyframes menuDown {
  0% {
    top: -100%;
  }
  100% {
    top: 0;
  }
}

@keyframes menuUp {
  0% {
    top: 0%;
  }
  100% {
    top: -100%;
  }
}

@keyframes buttonLeft {
  0% {
    left: 0;
    transform: rotate(0);
    background: var(--color-black);
  }

  100% {
    left: -50px;
    transform: rotate(130deg);
    background: var(--background);
  }
}

@keyframes buttonRight {
  0% {
    left: -50px;
    transform: rotate(130deg);
    background: var(--background);
  }

  100% {
    left: 0px;
    transform: rotate(0);
    background: var(--color-black);
  }
}
