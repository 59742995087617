:root {
  --color-black: #383838;
  --background: #f4f4f4;
  --veige-1: #c0b283;
  --veige-2: #ddd1c1;
  --veige-2: #b2a792;
  --veige-3: #fef5ef;
  --purple-1: #ba80e6;
  --purple-2: #d1abef;
  --purple-3: #e9d5f7;
  --purple-4: #391952;
  --purple-5: #8d73b0;
  --purple-6: #4717f7;
  --purple-7: #8756FF;
  --purple-8: #bbaaff;
  --purple-9: #6f47fc;
  --purple-10: #481d68;
}
