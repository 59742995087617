@import url('../../styles/variables.css');

.firstView{
    height: 100vh;
    background: none;
}

.me-container{
    width: 100%;
    height: 100vh;

    display: grid;
    place-content: center;
    place-items: center;

    background: var(--background);
}

.me{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    z-index: 3;
}

.me__name{
    font-size: 30px;
    font-weight: 500;
    color: var(--color-black);
}

.me__profesion{
    font-size: 50px;
    font-weight: bold;

    color: var(--color-black);
}

.me__profesion .fancy{
    color: var(--purple-9);
}

.skills{
    display: flex;
    overflow: hidden;
}

.slider-container{
    background: var(--purple-9);
    height: 100%;

    display: flex;
    flex-direction: column;

    gap: 30px;

    padding: 20px 0 20px 0;
}

.titles{
    padding:0 20px;
}

.titles h1, span{
    color: var(--background);
}

.titles h1{
    font-size: 35px;
}

.titles span{
    font-size: 15px;
    
}

.titles .fancy{
    font-weight: bolder;
    font-size: 16px;
}

.time-line{
    height: 100vh;
    width: 100%;
}

.about{
    padding: 0 20px;

    display: flex;
    flex-direction: column;
    gap: 100px;
}

.about_first, .about_second{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    place-content: center;
}

.about_first span, .about_second span{
    padding: 10px;
}


.about_first{
    text-align: end;
    opacity: 0;
}

.about_second{
    text-align: start;
    margin-bottom: 40px;
    opacity: 0;
}

.about_title{
    opacity: 0;
}

.about_image{
    width: 100%;
    border-radius: 20px;
}

.about_image:nth-child(2){
    width: 90%;
}

.year{
    font-size: 25px;
}

footer.home{
    height: 50vh;
    width: 100%;
    display: flex;
    justify-content: center;
    
    flex-direction: column;


    overflow: hidden;
}

.contact-me{
    padding: 0 20px;
}

.contact-me h1{
    font-size: 50px;
}

.email{
    text-decoration: none;
    color: var(--color-black);
    font-size: 12px;
}