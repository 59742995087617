@import "./styles/variables.css";

* {
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--background);
}

code {
  font-family: "Roboto", sans-serif;
}
