:root {
  --background-work: #0099ab;
}

.work {
  height: 100vh;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;

  padding: 15px 0;
}

.work_card {
  border: 3px solid var(--color-black);
  height: 200px;
  width: 95%;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  place-content: center;
  place-items: center;
  text-align: center;

  gap: 10px;

  padding: 0 10px;

  position: relative;
  overflow: hidden;
  opacity: 0;

  animation: opacity 1s 1s forwards;
}

.work_card span {
  color: var(--color-black);
}

.work_card h1,.work_card span, .urls {
  opacity: 0;
  animation: opacity 0.5s 1.5s forwards;
}

.work_card::before {
  content: "";
  background: var(--color-black);
  position: absolute;
  height: 100%;
  width: 0;
  left: 0;

  animation: showProyects 1.8s 1.2s forwards;
  z-index: 4;
}

.urls{
  display: flex;
  gap: 10px;
}

.urls > a{
  text-decoration: none;
  color: var(--color-black);
  font-size: 25px;
}

@media (max-width: 991px) {
  .work{
    width: 95%;
    margin: auto;
  }
}

@keyframes showProyects {
  0% {
    width: 0;
    left: 0%;
  }
  20% {
    width: 100%;
    left: 0%;
  }
  100% {
    left: 100%;
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}