@import url('../../styles/variables.css');

.loading{
    position: fixed;
    top: 0;

    width: 100%;
    height: 100vh;
    background: var(--background);

    animation: upAnimation 0.8s 2s forwards reverse;

    z-index: 1000;

    pointer-events: none;
}

.loading-container{
    display: grid;
    place-content: center;

    height: 100%;
}

.loading-container__name{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    overflow: hidden;
}

.loading-container__name span{
    animation: upAnimation 0.5s forwards;
    position: relative;
    font-size: 100px;
    top: 100%;
    font-weight: 500;
    color: var(--color-black);
}

.position{
    position: absolute;
    bottom: 10px;
    text-align: center;
    width: 100%;

    animation: animationOpacity 0.5s 1s forwards;
    opacity: 0;
}

.position h1{
    color: var(--color-black);

}

@keyframes upAnimation {
    0%{
        top: 100%;
    }
    100%{
        top: 0%;
    }
}

@keyframes animationOpacity {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}